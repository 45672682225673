<template>
    <div class="container-fluid px-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Ordenes</h1>
        </div>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active">Mis Ordenes</li>
        </ol>  

        <div class="card-body">
                <table id="datatablesSimple" class="datatable-table">
                    <thead>
                        <tr>
                            <th>Orden ID</th>
                            <th>Fecha</th>
                            <th>Articulos</th>
                            <th>Estatus</th>
                            <th>Pago</th>
                            <th>Total</th>
                            <th>Canal de venta</th>
                        </tr>
                    </thead>
  
                   <tbody>
                        <tr v-for="user in userData" :key="user.id">
                            <td>#{{ user.idOrden }}</td>
                            <td>{{ user.fechaRegistro }}</td>
                            <td></td>       
                            <td></td>  
                            <td></td>  
                            <td></td>  
                            <td></td>  
                        </tr>
                    </tbody>
                </table>
                </div>
    </div>



     
</template>
<script>
import AuthService from '@/services/AuthService.js';
export default {

data() {
  return {
      userData: [],
      skuSeleccionado: null,
      productoSelecionado: null,
      descripcionSeleccionado: null,
      precioSeleccionado: null,
      idSeleccionado: null,
      descuentoSeleccionado: null, 
      imgSeleccionado: null,
      cantidadSeleccionado: null,
      categoSeleccionado: null,
      isLoading: false,
      img: null
  };
},
props: ['userEmail'],
async created() {
  try {
    console.log(this.userEmail);
    this.userData = await AuthService.getOrdenes(this.userEmail);

    console.log(this.userData);
  }catch (error) {
      console.log('Error al obtener la información del usuario:', error);
    }
  }
}
</script>