<template>
    <div>
      <div class="row">
        <div class="col-sm-2">
          <img src="../../assets/img/pae.png" width="64px" class="border rounded" />
        </div>
        <div class="col-sm-8">
          <p class="section-text-4 mb2">Pago al entregar</p>
          <p class="text-8">Reciba pagos en efectivo al momento de la entrega.</p>
        </div>
        <div class="col-sm-2">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="isChecked"
              @change="handleCheckboxChange"
            />
          </div>
        </div>
      </div>
  
      <div v-if="alertVisible" class="alert alert-success   alert-dismissible fade show" role="alert">
        <strong>{{ alertMessage }}</strong>
        <button type="button" class="btn-close" @click="closeAlert" aria-label="Close"></button>
      </div>
    </div>
  </template>
  
  <script>
  import AuthService from '@/services/AuthService.js';
  
  export default {
    props: ['nombreTienda', 'idTienda', 'userTienda'],
    data() {
      return {
        isChecked: false,
        paeValue: 'no',
        paypalEmail: '',
        alertVisible: false,
        alertMessage: '',
      };
    },
    watch: {
      idTienda: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.verificarMetodoDePago();
          }
        }
      }
    },
    methods: {
      async verificarMetodoDePago() {
        try {
          console.log("idTienda:", this.idTienda);
          const idTiendaStr = String(this.idTienda);
          const response = await AuthService.obtenerPayPalEmail(idTiendaStr, 'PAE');
          console.log('PAE:', response.metodos.activo);
          this.isChecked = response.metodos.activo;
        } catch (error) {
          console.error('Error al obtener metodo de pago:', error);
          this.showAlert('Error al obtener el método de pago');
        }
      },
      async handleCheckboxChange(event) {
        console.log("Checkbox value: ", event.target.checked);
        try {
          const response = await AuthService.metodoDePago(this.nombreTienda, this.idTienda, this.userTienda, 'noUser', 'PAE', this.isChecked);
          console.log(response);
          this.showAlert('Metodo de Pago actualizado exitosamente');
        } catch (error) {
          console.error('Error al actualizar el pago:', error);
        //  this.showAlert('Hubo un error al actualizar el pago');
        } finally {
          this.isLoading = false;
        }
      },
      showAlert(message) {
        this.alertMessage = message;
        this.alertVisible = true;
      },
      closeAlert() {
        this.alertVisible = false;
      }
    },
    mounted() {
      if (this.idTienda) {
        this.verificarMetodoDePago();
      }
    }
  };
  </script>
  