<template>
    <div class="three-dots-spinner">
      <div class="dot" v-for="n in 3" :key="n"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ThreeDotsSpinner'
  }
  </script>
  
  <style scoped>
  .three-dots-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #007bff;
    border-radius: 50%;
    animation: dot-flashing 1s infinite linear both;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dot-flashing {
    0% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0.3;
    }
  }
  </style>
  