import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import ResetView from '../views/ResetView.vue';
import DashboardView from '../views/DashView.vue'; 
import DashboardHome from '@/views/DashboardHome.vue';  
import DashboardSettings from '@/views/DashboardSettings.vue';
import DashboardProductos from '@/views/DashboardProductos.vue';
import DashboardNewProducts from '@/views/DashboardNewProducts.vue';
import DashboardTemas from '@/views/DashboardTemas.vue';
import DashboardOrders from '@/views/DashboardOrders.vue';

//const router = createRouter({
  //history: createWebHistory(import.meta.env.BASE_URL),
 // routes: [
 //   {
//      path: '/',
 //     name: 'login',
 //     component: LoginView
  //  }
    /*,
    {
      path: '/registro',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('../views/ResetView.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../views/DashboardView.vue'),
      meta: { requiresAuth: true },
      props: true
    }*/
//  ];
//});
const routes = [
    { path: '/', component: LoginView },
    { path: '/register', component: RegisterView },
    { path: '/reset', component: ResetView },
    {
      path: '/dashboard',
      component: DashboardView, 
      children: [
        { path: '', component: DashboardHome }, // Ruta por defecto del dashboard
        { path: '/settings', component: DashboardSettings }, // Ruta para la configuración del dashboard
        { path: '/products', component: DashboardProductos },
        { path: '/products/new-product', component: DashboardNewProducts},
        { path: '/themes', component: DashboardTemas},
        { path: '/orders', component: DashboardOrders },
        // Agrega más rutas según sea necesario
      ],
      meta: { requiresAuth: true }, props: true
    }
    
  ];

const router = createRouter({
    history: createWebHistory(),
    routes
  });
  
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // Verificar si el usuario está autenticado
      //console.log('token guardado en sessionStorage:', sessionStorage.getItem('token'));
      if (!sessionStorage.getItem('token')) {
        // Si no hay un token en el almacenamiento local, redirigir al login
        next('/');
      } else {
        next();
      }
    } else {
      next();
    }
  });
export default router;