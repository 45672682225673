<template>
    <section class="min-vh-100-light py-5">
      <div class="container">
        <div class="row justify-content-center mb-md-6">
          <div class="col-auto">
            <a href="index.html">
              <img src="../assets/img/logo-2.jpg" alt="Saldi">
            </a>
          </div>
        </div>
        <div class="row text-center py-6">
          <div class="col">
            <h2 class="h1">Registro Exitoso!</h2>
            <div class="lead mb-4">Gracias por registrarte! 🙏 Recibirás un correo electrónico confirmando tu suscripción en breve.</div>
            <a class="btn btn-primary btn-lg" href="/">Iniciar sesión</a>
          </div>
        </div>
      </div>
    </section>
</template>
<style scoped>
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn {
    letter-spacing: -.02em;
}
.btn-group-lg>.btn, .btn-lg {
    padding: .875rem 2.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3125rem;
}
.btn-primary {
    color: #fff;
    background-color: #3755be;
    border-color: #3755be;
}
.btn-primary:hover {
    color: #fff;
    background-color: #2e48a0;
    border-color: #2c4396;
}
 
</style>