<template>
    <div class="container-fluid px-4">
        <h1 class="mt-4">Dashboard</h1>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">Dashboard</li>
        </ol>

        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div class="card mb-4">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Link Tienda
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <template v-if="loading">
                        <DotsComponent />
                      </template>
                      <template v-else>
                        <span class="small" v-if="nombreTienda.trim() === ''">Agrega el nombre tu tienda</span>
                        <a v-else class="link-color" :href="'https://saldi.me/'+nombreTienda" target="_blank">
                          <span class="small">https://saldi.me/{{ nombreTienda }}</span>
                        </a>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Editar nombre tienda
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <form @submit.prevent="guardar">
                          <div class="input-group mb-3">
                              <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" v-model="tienda" name="tienda" placeholder="mitienda" required>
                          </div>
                          <button class="btn btn-primary btn-sm" type="submit"> 
                              {{ isLoading ? '' : 'Guardar' }}
                              <LoaderComponent v-if="isLoading"/>    
                          </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>        
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card mb-4">
              <div class="card-body">Productos en tienda</div>
              <div class="card-footer d-flex align-items-center justify-content-between">
                <a class="small  stretched-link" href="#">View Details</a>
                <div class="small "><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></div>
              </div>
            </div>
          </div>

                            <div class="col-xl-3 col-md-6">
                                <div class="card bg-success text-white mb-4">
                                    <div class="card-body">Success Card</div>
                                    <div class="card-footer d-flex align-items-center justify-content-between">
                                        <a class="small text-white stretched-link" href="#">View Details</a>
                                        <div class="small text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div class="card bg-danger text-white mb-4">
                                    <div class="card-body">Danger Card</div>
                                    <div class="card-footer d-flex align-items-center justify-content-between">
                                        <a class="small text-white stretched-link" href="#">View Details</a>
                                        <div class="small text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg><!-- <i class="fas fa-angle-right"></i> Font Awesome fontawesome.com --></div>
                                    </div>
                                </div>
                            </div>
                        </div>

    </div>
</template>
<script>
import AuthService from '@/services/AuthService.js';
import LoaderComponent from '@/components/LoaderComponent.vue';
import DotsComponent from '@/components/DotsComponent.vue';
export default {
    props: ['userEmail'],
  data() {
    return {
        tienda: '',
        nombreTienda:'',
        isLoading: false,
        loading: true
    };
  },
  async created() {
    try {
    //    this.isLoading = true; 
      // console.log('Valor de uEmail:', this.userEmail);
      // Hacer una solicitud al servidor para obtener la información del usuario
      
      this.userData = await AuthService.getUserInfo(this.userEmail);
      // Actualizar el estado del usuario con la información obtenida
    //  this.info = userData;
    //  console.log(this.userData.nom_tienda);
      this.nombreTienda = this.userData.nom_tienda;
      this.isLoading = false;

    } catch (error) {
      console.log('Error al obtener la información del usuario:', error);
    } finally{
      this.loading = false;
    }
  },
  methods:{
    async guardar() {
        this.isLoading = true; 
        try {
            console.log('Guardando nombre de tienda...',this.userEmail);
            let tiendaValue = this.tienda.replace(/\s+/g, '').trim().toLowerCase();
            const response = await AuthService.registraTienda(this.userEmail, tiendaValue);
            if (response.status === 200) {
                console.log("Se guardo con exito");
                this.$router.push('/dashboard');
            } 
            console.log(response); 
        } catch (error) {
            console.log(error)
        } finally {
          this.isLoading = false; 
      }
    }
  },
  components: {
    LoaderComponent,
    DotsComponent
  }
};
</script>
<style scoped>
.link-color{
    color: #ee741f;
}
.link-color:hover {
    color: #cc631b;
}
</style>