<template>
    <div class="container-fluid px-4"  style="background-color: #f7f7f7;">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="mt-4">Nuevo Producto</h1>
        </div>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item"><a href="/products">Productos</a></li>
            <li class="breadcrumb-item active">Nuevo Producto</li>
        </ol>
       
        <div class="row g-5">
            <div class="col-md-5 col-lg-4">
                <ul class="list-group mb-3">
                <li class="list-group-item d-flex justify-content-between lh-sm">
                    <div>
                    <h4 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-secondary">Navegacion rapida</span>
                    </h4>
                    <h6 class="my-0"><a href="#infoProduct" class="navRap"> <i class="fa-solid fa-circle-info"></i> Informacion del Producto</a></h6>
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-sm">
                    <div>
                    <h6 class="my-0"><a href="#imgProduct" class="navRap"><i class="fa-solid fa-image"></i> Imagenes del Producto</a></h6>
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-sm">
                    <div>
                    <h6 class="my-0"><a href="#inventario" class="navRap"><i class="fa-solid fa-truck-moving"></i> Inventario</a></h6>
                    </div>
                </li>
                </ul>
            </div>
      
            <div class="col-md-7 col-lg-8" >
                <form @submit.prevent="guardar">
                <ul class="list-group mb-3" id="infoProduct">
                    <li class="list-group-item justify-content-between lh-sm">
                        <h4 class="mb-3">Informacion del Producto</h4>
                        <p>Ingrese fácilmente detalles esenciales como nombre, precio y más para exhibir su producto.</p>
                        <div class="row g-3">
                            <div class="col-sm-6">
                                <label for="producto" class="form-label">Nombre Producto</label>
                                <input type="text" class="form-control" id="producto" v-model="producto" name="producto" placeholder="" value="" required="">
                            </div>
                            <div class="col-sm-6">
                                <label for="categoria" class="form-label">Categoria Producto</label>
                                <input type="text" class="form-control" id="categoria" v-model="categoria" name="categoria" placeholder="" value="" required="">
                            </div>

                            <div class="col-6">
                                <label for="precio" class="form-label">Precio</label>
                                <div class="input-group has-validation">
                                    <input type="text" class="form-control" id="precio" v-model="precio" name="precio" placeholder="$" required="">
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="descuento" class="form-label">Precio Descuento</label>
                                <div class="input-group has-validation">
                                    <input type="text" class="form-control" id="descuento" v-model="descuento" name="descuento" placeholder="$" required="">
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="descripcion" class="form-label">Descripcion del producto</label>
                                <textarea class="form-control" id="descripcion" v-model="descripcion" name="descripcion" rows="3"></textarea>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="list-group mb-3" id="imgProduct">
                    <li class="list-group-item justify-content-between lh-sm">
                        <div class="col-12">
                            <h4 class="mb-3">Imagenes del Producto</h4>
                            <label for="address" class="form-label">Sube imágenes y videos cautivadores para que tu producto se destaque.</label>
                            <input type="file" class="form-control" id="img" name="img" @change="handleFileUpload" accept="image/*">
                        </div>
                    </li>
                </ul>
                <ul class="list-group mb-3" id="inventario">
                    <li class="list-group-item justify-content-between lh-sm">
                        <div class="col-12">
                            <h4 class="mb-3">Inventario</h4>
                            <label for="address" class="form-label">Administre sus niveles de existencias sin problemas para mantenerse al día con la demanda de los clientes.</label>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="cantidad" class="form-label">
                                    Cantidad
                                    </label>
                                    <input type="text" class="form-control" id="cantidad" v-model="cantidad" name="cantidad" placeholder="" value="" required="">
                                </div>
                                <div class="col-sm-6">
                                    <label for="sku" class="form-label">SKU ID</label>
                                    <input type="text" class="form-control" id="sku" v-model="sku" name="sku" placeholder="" value="" required="">
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <hr class="my-4">
                <button class="w-100 btn btn-primary btn-lg" type="submit">
                    {{ isLoading ? '' : 'Agregar producto' }}
                    <LoaderComponent v-if="isLoading"/>
                </button>
            </form>
            </div>
        </div>
    </div>
</template>
<script>
import AuthService from '@/services/AuthService.js';
import LoaderComponent from '@/components/LoaderComponent.vue';
export default {
    props: ['userEmail'],
  data() {
    return {
        producto: '',
        categoria: '',
        precio: '',
        descuento: '',
        descripcion: '',
        cantidad: '',
        sku: '',
        image: null, 
        isLoading: false,
        idTienda:''
    };
  },
  methods:{
    handleFileUpload(event) {
        this.image = event.target.files[0];
    },
    async guardar() {
        this.isLoading = true; 
        try {
            
            const response = await AuthService.registraProducto(this.userEmail, this.producto, this.categoria, this.precio, this.descuento, this.descripcion, this.cantidad, this.sku, this.image, this.idTienda);
            if (response.status === 200) {
                console.log("Se guardo con exito");
                this.$router.push('/products');
            } 
            console.log(response); 
        } catch (error) {
            console.log(error)
        } finally {
          this.isLoading = false; 
      }
    }
  },
  components: {
    LoaderComponent
  }
};
</script>
<style>
.navRap{
    color: #4d4d4d;
    text-decoration: none;
    font-weight: 500;
}
.navRap:hover{
  color: #146eb4;
}
</style>