<template>
  <div class="container-fluid px-4" style="background-color: #f7f7f7;">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="mt-4">Configuración</h1>
    </div>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
      <li class="breadcrumb-item active">Configuración</li>
    </ol>

    <div class="row g-5">
      <div class="col-md-5 col-lg-4">
        <ul class="list-group mb-3">
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="text-secondary">Navegación rápida</span>
              </h4>
              <h6 class="my-0"><a href="#" @click="selectTab('Detalles de la tienda')" class="navRap"><i class="fa-solid fa-store"></i> Detalles de la tienda</a></h6>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 class="my-0"><a href="#" @click="selectTab('Dominio')" class="navRap"><i class="fa-solid fa-globe"></i> Dominio</a></h6>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 class="my-0"><a href="#" @click="selectTab('Métodos de pago')" class="navRap"><i class="fa-solid fa-money-bill"></i> Métodos de pago</a></h6>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 class="my-0"><a href="#" @click="selectTab('SEO')" class="navRap"><i class="fa-solid fa-magnifying-glass"></i> SEO</a></h6>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between lh-sm">
            <div>
              <h6 class="my-0"><a href="#" @click="selectTab('Usuarios adicionales')" class="navRap"><i class="fa-solid fa-people-group"></i> Usuarios adicionales</a></h6>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-md-7 col-lg-8">
        <TabsComponent ref="tabsComponent">
          <TabComponent name="Detalles de la tienda" :selected="true">
            <div class="list-group mb-3">
              <div class="tab-content list-group-item">
            <h4 class="mb-3">Detalles de la tienda</h4>
            <!-- Contenido de Detalles de la tienda -->
            <p>Ingrese fácilmente detalles esenciales como nombre, precio y más para exhibir su producto.</p>
                <div class="row g-3">
                    <div class="col-sm-6">
                        <label for="firstName" class="form-label">Link Tienda</label>
                        <div class="input-group has-validation">
                            <a :href="'https://saldi.me/'+nombreTienda" target="_blank" class="input-group-text form-control" style="text-decoration: none;">
                              saldi.me/{{ nombreTienda }}
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="lastName" class="form-label">Nombre de la Tienda</label>
                        <input type="text" class="form-control" id="lastName" placeholder="" :value="nombreTienda" required="">
                        <div class="invalid-feedback">
                            Valid last name is required.
                        </div>
                    </div>

                    <div class="col-6">
                        <label for="username" class="form-label">Email</label>
                        <div class="input-group has-validation">
                            <input type="text" class="form-control" id="username" :value="userEmail" required="">
                            <div class="invalid-feedback">
                                Your username is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="username" class="form-label">Pais</label>
                        <div class="input-group has-validation">
                            <input type="text" class="form-control" id="username" placeholder="" required="">
                            <div class="invalid-feedback">
                                Your username is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <label for="email" class="form-label">Ubicacion de la tienda</label>
                        <input type="text" class="form-control" id="username" placeholder="" required="">
                        <hr class="my-4">
                    </div>
                    
                    <div class="col-6">
                        <button class="btn btn-primary btn-sm" type="submit">
                            Guardar
                        </button>
                    </div>
                </div>
              </div>
            </div>  
          </TabComponent>

          <TabComponent name="Dominio">
            <div class="list-group mb-3">
              <div class="tab-content list-group-item">
                <h4 class="mb-3">Dominio</h4>
                <p>Configura y personaliza la dirección web de tu tienda.</p>
                <!-- Contenido de Dominio -->
                <table class="table ">
                  <thead>
                    <tr>
                      <th scope="col">
                        Nombre del dominio
                      </th>
                      <th scope="col">Status</th>
                      <th scope="col">Fecha </th>
                      <th scope="col">Proveedor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a :href="'https://saldi.me/'+nombreTienda" target="_blank">
                          https://saldi.me/{{ nombreTienda }}
                        </a>
                      </td>
                      <td><span class="badge bg-success">Live</span></td>
                      <td></td>
                      <td>Saldi</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="list-group mb-3">
              <div class="tab-content list-group-item">
                <h4 class="mb-3">Agregar dominio perzonalizado</h4>
                <p>Puede conectar su dominio existente a Dukaan en unos minutos.</p>
              </div>
            </div>
          </TabComponent>

          <TabComponent name="Métodos de pago">
            <div class="list-group mb-3">
              <div class="tab-content list-group-item">
                <h4 class="mb-3">Métodos de pago</h4>
                <p class="c-gray-1">Administre metodos de pago para aceptar los pagos de sus clientes.</p>
                  <div class="row">
                    <div class="col-sm-2">
                      <img src="../assets/img/paypal.png" width="64px" class="border rounded"> 
                    </div>
                    <div class="col-sm-6">
                      <p class="section-text-4 mb2">PayPal</p>
                      <p class="text-8">Recibe tus pagos con paypal.</p>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-end align-items-center">
                      <button 
                      class="btn btn-outline-secondary d-inline-flex align-items-center float-right" 
                      type="button"
                      data-bs-toggle="modal" 
                      data-bs-target="#exampleModal"
                    >
                      {{ paypalUser ? 'Editar' : 'Configurar' }}
                    </button>
                    &ensp;  
                    <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                      <li class="nav-item dropdown">
                          <a class="nav-link" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                              <li><a class="dropdown-item" href="#"><i class="fa fa-trash-o" aria-hidden="true"></i> Eliminar</a></li>
                          </ul>
                      </li>
                    </ul>
                    </div>
                  </div>
        
                <!--
                <ul>
                  <li class="list-group list-group-horizontal-md" style="align-items: center;">
                    <img src="../assets/img/paypal.png" width="64px" class="border rounded"> 
                    <p class="section-text-4">PayPal</p>
                    <button 
                      class="btn btn-outline-secondary d-inline-flex align-items-center float-right" 
                      type="button"
                      data-bs-toggle="modal" 
                      data-bs-target="#exampleModal"
                    >
                      {{ paypalUser ? 'Editar' : 'Configurar' }}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    
                    <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                      <li class="nav-item dropdown">
                          <a class="nav-link" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></a>
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                              <li><a class="dropdown-item" href="#"><i class="fa fa-trash-o" aria-hidden="true"></i> Eliminar</a></li>
                          </ul>
                      </li>
                    </ul>
                  </li>
                  <br/>
                  <li class="list-group list-group-horizontal-md" style="align-items: center;"><img src="../assets/img/stripe.png" width="64px" class="border rounded"> <p class="section-text-4">Stripe</p></li>
                  <br/>
                  <li class="list-group list-group-horizontal-md" style="align-items: center;">
                    <img src="../assets/img/clip.png" width="64px" class="border rounded"> <p class="section-text-4">Clip</p>
                  </li>
                  <br/>

                </ul>
                -->
                &ensp;  
                  
                <PaE :nombreTienda="nombreTienda" :idTienda="idTienda" :userTienda="userTienda"/>
              </div>
            </div>

            <!-- Contenido de Métodos de pago -->

          </TabComponent>

          <TabComponent name="SEO">
            <h4 class="mb-3">SEO</h4>
            <!-- Contenido de SEO -->
          </TabComponent>

          <TabComponent name="Usuarios adicionales">
            <h4 class="mb-3">Usuarios adicionales</h4>
            <!-- Contenido de Usuarios adicionales -->
          </TabComponent>
        </TabsComponent>
      </div>
    </div>
  </div>

  <PayPal :nombreTienda="nombreTienda" :idTienda="idTienda" :userTienda="userTienda" v-model:paypalUser="paypalUser"/>

</template>

<script>
import TabsComponent from '../components/TabsComponent.vue';
import TabComponent from '../components/TabComponent.vue';
import PayPal from '../components/metodosPago/PayPal.vue';
import PaE from '../components/metodosPago/PaE.vue';
import AuthService from '@/services/AuthService.js';
export default {
  data() {
    return {
        nombreTienda: null,
        idTienda: null,
        userTienda: null,
        paypalUser: '',
        isChecked: false
    };
  },
  props: ['userEmail'],
  components: {
    TabsComponent,
    TabComponent,
    PayPal,
    PaE
  },
  methods: {
    selectTab(tabName) {
      const tabsComponent = this.$refs.tabsComponent;
      const tab = tabsComponent.tabs.find(tab => tab.name === tabName);
      if (tab) {
        tabsComponent.selectTab(tab);
      }
    },
    handleCheckboxChange(event) {
      console.log("Checkbox value: ", event.target.checked);
      // Puedes usar this.isChecked para realizar otras acciones
    }
  },
  async created() {
    try {
    //    this.isLoading = true; 
    console.log('Valor de uEmail:', this.userEmail);
      // Hacer una solicitud al servidor para obtener la información del usuario
      
      this.userData = await AuthService.getUserInfo(this.userEmail);
      // Actualizar el estado del usuario con la información obtenida
    //  this.info = userData;
      console.log(this.userData);
      this.nombreTienda = this.userData.nom_tienda;
      this.idTienda = this.userData.id_tienda;
      this.userTienda = this.userData.user_email;
      this.isLoading = false;

    } catch (error) {
      console.log('Error al obtener la información del usuario:', error);
    }
  }
};
</script>
<style>
.navRap{
    color: #4d4d4d;
    text-decoration: none;
    font-weight: 500;
}
.navRap:hover{
  color: #146eb4;
}
ul{
  list-style-type: none;
}
.section-text-4 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
  /*  padding: 0 150px 0 15px; */
}
.c-gray-1 {
    color: gray;
}
.text-8 {
    font-size: 14px;
    line-height: 20px;
}
.mb2 {
    margin-bottom: 2px;
}
</style>