<template>
    <div class="sb-nav-fixed">
        <NavBar/>
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div class="sb-sidenav-menu">
                        <SideNav/>
                    </div>
                    <div class="sb-sidenav-footer">
                        <div class="small">
                          Conectado como: {{ user.nombre ? user.nombre.charAt(0).toUpperCase() + user.nombre.slice(1) : '' }}
                        {{ user.apellido ? user.apellido.charAt(0).toUpperCase() + user.apellido.slice(1) : '' }}
                        </div> 
                    </div>
                </nav>
            </div>
            <div id="layoutSidenav_content">
                <main>
                    <router-view :userEmail="email"></router-view>
                </main>
                <FooTer/>
            </div>    
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import NavBar from '@/components/NavBar.vue';
import SideNav from '@/components/SideNav.vue';
import FooTer from '@/components/FooTer.vue';
import AuthService from '@/services/AuthService.js';

export default {
    computed: {
    // Mapea el email del usuario desde el store a una propiedad computada
    ...mapState(['userEmail'])
  },
  data() {
    return {
    //  sidebarOpen: false,
      user: '',
      email: '',
    //  showModal: false
    };
  },
  //props: ['userEmail'],
  async created() {
    try {
      this.email = this.userEmail;
      // Hacer una solicitud al servidor para obtener la información del usuario
      const userData = await AuthService.getUserInfo(this.email);
      // Actualizar el estado del usuario con la información obtenida
      this.user = userData;

    } catch (error) {
      console.log('Error al obtener la información del usuario:', error);
    }
  },
    components: {
        NavBar,
        SideNav,
        FooTer
    },
    mounted() {
    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      sidebarToggle.addEventListener('click', event => {
        event.preventDefault();
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      });
    }
  }
}
</script>
<style>
.body {
    height: 100%;
}
 

</style>