<template>
    <section class="row no-gutters min-vh-100 p-0 forma">
      <div class="col-lg-4 bg-primary-3 d-flex justify-content-end">
        <img src="../assets/img/desk.jpg" alt="Image" class="bg-image">
        <div class="divider divider-vertical d-none d-lg-block">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none" width="100%" height="100%" version="1.1" class="injected-svg" data-src="assets/img/dividers/divider-vertical-1.svg">
     <path d="M0,0 L100,0 L100,100 L0,100 C66.6666667,83.3333333 100,66.6666667 100,50 C100,33.3333333 66.6666667,16.6666667 0,0 Z" fill="#ffffff"></path>
</svg>
        </div>
      </div>
      <div class="col px-5 position-relative d-flex align-items-center">
        <div class="row justify-content-center w-100">
          <div class="col-md-8 col-lg-7 col-xl-6">
            <div class="text-center"> <img src="../assets/img/logo-2.jpg" alt="Image" class="text-center"></div>
            <div class="text-center mb-4">
              <h1 class="mb-1">Inicio sesión</h1>
              <span>Ingrese los detalles de su cuenta a continuación</span>
            </div>
            <form @submit.prevent="login" >
              <div class="form-group">
                <input type="email" placeholder="Correo Electronico" v-model="email" name="email" class="form-control" required>
                <span v-if="email" class="error">{{ emailError }}</span>
              </div>
              <div class="form-group">
                <input :type="showPassword ? 'text' : 'password'" placeholder="Contraseña" v-model="password" name="psw" class="form-control" required>
                <button type="button" class="toggle-password-btn" @click="togglePasswordVisibility">
                  <svg v-if="showEye" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                  </svg>
                </button>
                <a class="small" href="/reset">Olvidaste tu Contraseña?</a>
                <span v-if="passwordError" class="error">{{ passwordError }}</span>  
                <span v-if="errorMsg" class="error">{{ errorMsg }}</span>
              </div>
              <div class="form-group">
                <button class="btn-block btn btn-primary" type="submit">
                  {{ isLoading ? '' : 'Inicio sesión' }}
                  <LoaderComponent v-if="isLoading"/>
                </button>
              </div>
              <hr>
              <div class="text-center text-small text-muted">
                <span>No tienes cuenta? <a href="/register">Registrate aqui!</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import AuthService from '@/services/AuthService.js';
import LoaderComponent from '@/components/LoaderComponent.vue';
export default {
  data() {
    return {
      email: '',
      password: '',
      emailError: '',
    //  Msg: 'Inicio sesión',
      passwordError: '',
      isLoading: false,
    //  user: null,
    showPassword: false,
    showEye: true,
      errorMsg: ''
    };
  },
  mounted() {
    // Verifica si hay un token en sessionStorage al cargar la página
    if (sessionStorage.getItem('token')) {
        // Si hay un token, redirecciona al Dashboard
      this.$router.push('/dashboard');
    }
  },
  methods: {
    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
        this.showEye = !this.showEye;
    },
    async login() {
      this.emailError = '';
      this.passwordError = '';
      this.isLoading = true; 

      if (!this.email) {
        this.usernameError = 'Introduce tu correo electronico.';
      }

      if (!this.password) {
        this.passwordError = 'Introduce tu contraseña.';
      }

      if (!this.emailError && !this.passwordError) {
        try{
        // Aquí puedes realizar una llamada a una API para autenticar al usuario
          console.log('Autenticado usuario...');
          const response = await AuthService.login(this.email, this.password);
          console.log('response:', response); 
          console.log('response.status:', response.status);
          if (response.status === 200) {
            
            this.user = response.data.user;
            const token = response.data.token; 
            const userEmail = response.data.email; 
            console.log('Usuario autenticado: ', userEmail);
            console.log('token:', token);
            sessionStorage.setItem('token', token);
            this.$store.dispatch('login', { token, userEmail: this.email });
            this.$router.push('/dashboard');
          } else {
               // Mostrar mensaje de error si las credenciales son incorrectas
          //     console.log('Error al autenticar usuario');
            if (response.status === 401) {
                this.errorMsg = 'Usuario o contraseña incorrectos.';
                console.log(this.errorMsg);
            } else {
                // Otros códigos de estado de respuesta
                this.errorMsg = 'Usuario o contraseña incorrectos.';
              //  console.log(response.data.message);
            }
          }
        } catch (error) {
          this.errorMsg = 'Usuario o contraseña incorrecto.';
          console.error('Error', error);
        } finally {
          this.isLoading = false;
        }
  
      }
    }
  },
  components: {
    LoaderComponent
  }
};
</script>
<style scoped>
.toggle-password-btn {
    position: absolute;
    top: 30%;
    right: 10px; /* Ajusta el valor según sea necesario */
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}
    .body{
        height: 100%;
    }
    
/**/
.min-vh-100 {
    min-height: 100vh !important;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.bg-image:not([class*=absolute]) {
    position: absolute;
}
img.bg-image {
    object-fit: cover;
}
.bg-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
.no-gutters>.col, .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.d-flex {
    display: flex !important;
}
.bg-primary-3 {
    background-color: #1b1f3b !important;
}
@media (min-width: 992px) {
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }


}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
}
.position-relative {
    z-index: 1;
}

.pl-5, .px-5 {
    padding-left: 3rem !important;
}
.pr-5, .px-5 {
    padding-right: 3rem !important;
}
.position-relative {
    position: relative !important;
}
.align-items-center {
    align-items: center !important;
}
.d-flex {
    display: flex !important;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
.w-100 {
    width: 100% !important;
}
.justify-content-center {
    justify-content: center !important;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
}
.form-group {
    position: relative;
}
.form-group {
    margin-bottom: 1rem;
}
@media (min-width: 992px) {
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn {
    letter-spacing: -.02em;
}

.btn-block {
    display: block;
    width: 100%;
}
.btn-primary {
    color: #fff;
    background-color: #3755be;
    border-color: #3755be;
}
 
.btn-primary:hover {
    color: #fff;
    background-color: #2e48a0;
    border-color: #2c4396;
}
a {
    font-weight: 600;
    color: #3755be;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #263a83;
    text-decoration: none;
}
.text-small {
    font-size: .875rem;
    line-height: 1.25rem;
}
.text-small, small {
    font-weight: 600;
}
.text-muted {
    color: #adb5bd !important;
}
.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .875rem + 2px);
    padding: .4375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: .3125rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}
</style>