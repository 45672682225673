<template>
    <footer class="py-4 bg-light mt-auto">
        <div class="container-fluid px-4">
            <div class="d-flex align-items-center justify-content-between small">
                <div class="text-muted">Copyright © Saldi.mx 2024</div>
                <div>
                    <a href="https://saldi-web.onrender.com/privacy">Politicas de Privacidad</a>
                    ·
                    <a href="https://saldi-web.onrender.com/terms">Terminos y Condiciones</a>
                </div>
            </div>
        </div>
    </footer>
</template>