<template>
    <!--  
  <div class="list-group mb-3"
    -->
    <!--   
    <div class="tab-header">
      <button 
        v-for="tab in tabs" 
        :key="tab.name" 
        @click="selectTab(tab)" 
        :class="{ active: tab.isActive }">
        {{ tab.name }}
      </button>
    </div>

    <div class="tab-content list-group-item">
 -->
       <slot></slot>
       <!--
    </div>
  </div>
-->
</template>

<script>
export default {
  name: 'TabsComponent',
  data() {
    return {
      tabs: []
    };
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name === selectedTab.name);
      });
    },
    addTab(tab) {
      if (this.tabs.length === 0) {
        tab.isActive = true;
      }
      this.tabs.push(tab);
    }
  }
};
</script>

<style>
.tab-header {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab-header button {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
}

.tab-header button.active {
  border-bottom: 2px solid #007bff;
}

.tab-content {
  padding: 20px;
  background-color: #fff;
}
</style>
