<template>
    <div class="container-fluid px-4">
          <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 class="h2">Temas</h1>
          </div>
          <ol class="breadcrumb mb-4">
              <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
              <li class="breadcrumb-item active">Mis Temas</li>
          </ol>  
          <div class="text-bg-dark mb-4 bg-body-tertiary rounded-3">
        <div class="container-fluid py-5">
          <h2 class="display-5 fw-bold">Eleva el aspecto de tu tienda con Temas Saldi</h2>
          <p class="col-md-12 fs-4">Descubre el tema perfecto para tu tienda Saldi: estilo, eficiencia y personalización a tu alcance.</p>
        </div>
      </div>
      <div class="my-5">
          <ul class="row row-cols-3 row-cols-sm-4 row-cols-lg-6 row-cols-xl-8 list-unstyled list">
              <li class="col mb-4" style="width: 20rem;">
                  <div :class="['card', { borderColor: '0' === theme }]"  style="height: 300px;">
                      <div class="preview">
                          <img src="https://assets.startbootstrap.com/img/screenshots/templates/shop-homepage.png" alt="Avatar" class="image card-img-top">
                          <div class="middle">
                              <a href="#" target="_blank" class="btn btn-warning">
                                  Probar &nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
                              </a>
                          </div>
                      </div>
  
                      <div class="card-body">
                          <h5 class="card-title">Default</h5>
                          <a @click="applyTheme('default')" class="btn btn-primary">
                            {{ isLoading.default ? '' : 'Aplicar' }}
                            <LoaderComponent v-if="isLoading.default"/>
                          </a>
                      </div>
                  </div>
              </li>
              <li class="col mb-4" style="width: 20rem;">
                  <div :class="['card', { borderColor: theme === '1' }]" style="height: 300px;">
                      <div class="preview">
                          <img src="https://themewagon.com/wp-content/uploads/2023/08/furni.png" alt="Avatar" class="image card-img-top">
                          <div class="middle">
                              <a href="https://saldi-web.onrender.com/demo/muebleria" target="_blank" class="btn btn-warning">
                                  Probar &nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
                              </a>
                          </div>
                      </div>
  
                      <div class="card-body">
                          <h5 class="card-title">Muebleria</h5>
                          <a @click="applyTheme('muebleria')" class="btn btn-primary">
                            {{ isLoading.muebleria ? '' : 'Aplicar' }}
                            <LoaderComponent v-if="isLoading.muebleria"/>
                          </a>
                      </div>
                  </div>
              </li>
              <li class="col mb-4" style="width: 20rem;">
                  <div :class="['card', { borderColor: '2' === theme }]"  style="height: 300px;">
                      <div class="preview">
                          <img src="https://themewagon.com/wp-content/uploads/2024/03/waGGy.png" alt="Avatar" class="image card-img-top">
                          <div class="middle">
                              <a href="https://saldi-web.onrender.com/demo/petstore" target="_blank" class="btn btn-warning">
                                  Probar &nbsp;<i class="fa fa-external-link" aria-hidden="true"></i>
                              </a>
                          </div>
                      </div>
                      <div class="card-body">
                          <h5 class="card-title">Pet Store</h5>
                          <a @click="applyTheme('petStore')" class="btn btn-primary">
                            {{ isLoading.petStore ? '' : 'Aplicar' }}
                            <LoaderComponent v-if="isLoading.petStore"/>
                          </a>
                      </div>
                  </div>
              </li>       
          </ul>
      </div>
    </div>
  </template>
<script>
import AuthService from '@/services/AuthService.js';
import LoaderComponent from '@/components/LoaderComponent.vue';
export default {
    props: ['userEmail'],
    data() {
        return {
            isLoading: {
                default: false,
                muebleria: false,
                petStore: false
            },
            theme: null,
            tema:null,
            idTienda:null
        };
    },
    async created() {
        try {
            
            console.log(this.userEmail);
            this.userData = await AuthService.getUserInfo(this.userEmail);
            this.theme = this.userData.tema;
            this.idTienda = this.userData.id_tienda

            console.log("Theme:",this);

           
        } catch (error) {
            console.log('Error al obtener la información del usuario:', error);
        }
    },
    methods: {
        async applyTheme(themeName) {
            console.log('Tema aplicado:', themeName);

            if(themeName==='default'){
                this.tema='0'
            }
            if(themeName==='muebleria'){
                this.tema='1'
            }
            if(themeName==='petstore'){
                this.tema='2'
            }
            try {
                this.isLoading[themeName] = true; 
                
                const response = await AuthService.actualizarTema(this.userEmail, this.idTienda, this.tema)
                console.log(response);
                // Aquí puedes agregar lógica adicional si es necesario, como mostrar un mensaje de éxito.
            } catch (error) {
                console.error('Error:', error);
                // Aquí puedes agregar lógica para manejar el error, como mostrar un mensaje al usuario.
            } finally {
                this.isLoading[themeName] = false; 
            }
        }
    },
    components: {
        LoaderComponent
    }
}
</script>
  <style scoped>
  /*
  @media (min-width: 576px) {
      .row-cols-sm-4 > * {
          flex: 0 0 auto;
          width: 50%;
      }
  }
  */
  /*
  .row-cols-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
  }*/
  /*
  @media (min-width: 576px) {
      .row-cols-sm-4 > * {
          flex: 0 0 auto;
          width: 50%;
      }
  }
  
  .row-cols-3 > * {
      flex: 0 0 auto;
      width: 100%;
  }
  @media (min-width: 992px) {
      .row-cols-lg-6 > * {
          flex: 0 0 auto;
          width: 16.6666666667%;
      }
  }
  */
  
  .preview {
    position: relative;
  /*  width: 50%;*/
  }
  
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .preview:hover .image {
    opacity: 0.3;
  }
  
  .preview:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }
  .borderColor{
    border: solid;
    border-color: darkorange;
  }
  </style>