<template>
    <div class="container-fluid px-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Productos</h1>
            <div class="btn-toolbar mb-2 mb-md-0">
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-database-add" viewBox="0 0 16 16">
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0"/>
                        <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"/>
                    </svg> Nuevo producto
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="/products/new-product">Agregar</a></li>
                </ul>
            </div>
            </div>
        </div>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active">Mis Productos</li>
        </ol>
        <div class="row">
            <LoaderComponent v-if="isLoading"/>
            <div class="container my-5">
                <div v-if="userData.length === 0" class="position-relative p-5 text-center text-muted bg-body border border-dashed rounded-5">
                    <button type="button" class="position-absolute top-0 end-0 p-3 m-3 btn-close bg-secondary bg-opacity-10 rounded-pill" aria-label="Close"></button>
                    <svg class="bi mt-5 mb-3" width="48" height="48"><use xlink:href="#check2-circle"></use></svg>
                    <h1 class="text-body-emphasis">Añade tus productos a la tienda.</h1>
                    <p class="col-lg-6 mx-auto mb-4">
                        Comience a vender enumerando sus productos y compartiéndolos en sus redes sociales.
                    </p>
                    <a href="/products/new-product" class="btn btn-primary px-5 mb-5 button" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-database-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0"/>
                            <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"/>
                        </svg> Agregar producto
                    </a>
                </div>
            </div>
        </div>
        
        <div v-if="userData.length > 0" class="card mb-4">
            <div class="card-header">
                <i class="fas fa-table me-1"></i>
                Lista de productos
            </div>
            <div class="card-body">
                <table id="datatablesSimple" class="datatable-table">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Producto</th>
                            <th>Descripcion</th>
                            <th>Precio</th>
                            <th>Sku</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
  
                   <tbody>
                        <tr v-for="user in userData" :key="user.id">
                            <td><img :src="'data:image/jpg;base64,' + user.imageBase64" alt="Product Image" width="50" height="50"></td>
                            <td>{{ user.producto }}</td>
                            <td>{{ user.descripcion }}</td>
                            <td>{{ user.precio }}</td>
                            <td>{{ user.sku }}</td>                  
                            <td>
                                <button class="btn btn-info" 
                                    @click="modificarProducto(user.sku, user.producto, user.descripcion, user.precio, user._id, user.descuento, user.cantidad, user.categoria)" 
                                    type="button" 
                                    data-bs-toggle="offcanvas" 
                                    data-bs-target="#offcanvasRight" 
                                    aria-controls="offcanvasRight"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                    </svg>
                                </button>
                                &nbsp;
                                <button class="btn btn-danger" 
                                type="submit" 
                                data-bs-toggle="modal" 
                                data-bs-target="#exampleModal"
                                @click="eliminaProducto(user.sku, user._id)" 
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                    </svg>
                                </button>
                            </td>  
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        <div>
    </div>
</div>
<!-- offcanvas -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">Modificar articulo SKU: {{ skuSeleccionado }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
        <div class="col-12">
            <label for="skuSelecionado" class="form-label"><b>Modificar SKU</b></label>
            <div class="input-group has-validation">
                <input type="text" class="form-control" id="skuSelecionado" v-model="skuSeleccionado">
            </div>
            <br>
        </div>

        <div class="col-12">
            <label for="productoSelecionado" class="form-label"><b>Producto</b></label>
            <div class="input-group has-validation">
                <input type="text" class="form-control" id="productoSelecionado" v-model="productoSelecionado">
            </div>
            <br>
        </div>
        
        <div class="col-12">
            <label for="descripcionSeleccionado" class="form-label"><b>Descripción</b></label>
            <div class="input-group has-validation">
                <textarea class="form-control" id="descripcionSeleccionado" v-model="descripcionSeleccionado" rows="4"></textarea>
            </div>
            <br>
        </div>
  
        <div class="col-12">
            <label for="categoriaSeleccionado" class="form-label"><b>Categoria</b></label>
            <div class="input-group has-validation">
                <input type="text" class="form-control" id="categoriaSeleccionado" v-model="categoSeleccionado">
            </div>
            <br>
        </div>
   
        <div class="col-12">
            <label for="cantidadSeleccionado" class="form-label"><b>Cantidad Articulos</b></label>
            <div class="input-group has-validation">
                <input type="text" class="form-control" id="cantidadSeleccionado" v-model="cantidadSeleccionado">
            </div>
            <br>
        </div>
 
        <div class="col-12">
            <label for="precioSeleccionado" class="form-label"><b>Precio</b></label>
            <div class="input-group has-validation">
                <input type="text" class="form-control" id="precioSeleccionado" v-model="precioSeleccionado">
            </div>
            <br>
        </div>
 
        <div class="col-12">
            <label for="descuentoSeleccionado" class="form-label"><b>Descuento</b></label>
            <div class="input-group has-validation">
                <input type="text" class="form-control" id="descuentoSeleccionado" v-model="descuentoSeleccionado">
            </div>
            <br>
        </div>
        <div class="col-12">
            <label for="img" class="form-label">Actualizar Imagen</label>
            <input type="file" class="form-control" id="img" name="img" @change="handleFileUpload" accept="image/*">
        </div>
        <div class="col-12">
            <hr>
            <button class="btn-block btn btn-primary" @click="actualizarProducto">
                {{ isLoading ? '' : 'Actualizar' }}
                <LoaderComponent v-if="isLoading"/>
            </button>
        </div>
        
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Borrar Articulo</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Estas a punto de eliminar el articulo con numero de SKU: {{ skuSeleccionado }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" @click="eliminar()">Eliminar</button>
      </div>
    </div>
  </div>
</div>
</template>
<script>

import AuthService from '@/services/AuthService.js';
import LoaderComponent from '@/components/LoaderComponent.vue';
export default {

  data() {
    return {
        userData: [],
        skuSeleccionado: null,
        productoSelecionado: null,
        descripcionSeleccionado: null,
        precioSeleccionado: null,
        idSeleccionado: null,
        descuentoSeleccionado: null, 
        imgSeleccionado: null,
        cantidadSeleccionado: null,
        categoSeleccionado: null,
        isLoading: false,
        img: null
    };
  },
  props: ['userEmail'],
  async created() {
    try {
    //    this.isLoading = true; 
      console.log(this.userEmail);
      // Hacer una solicitud al servidor para obtener la información del usuario
      this.userData = await AuthService.getProductos(this.userEmail);
      // Actualizar el estado del usuario con la información obtenida
    //  this.info = userData;
      console.log(this.userData);
    this.isLoading = false;

    } catch (error) {
      console.log('Error al obtener la información del usuario:', error);
    }
  },
  methods: {
    handleFileUpload(event) {
        this.image = event.target.files[0];
    },
    async modificarProducto(sku, producto, descripcion, precio, _id, descuento, cantidad, categoria) {
    //    console.log("sku:",sku, "producto:",producto, "descripcion:",descripcion, "precio:",precio, "_id:",_id, "descuento:",descuento, "cantidad:",cantidad, "categoria:",categoria);
    // Asignar los valores recibidos a las propiedades del componente Vue.js
        this.skuSeleccionado = sku;
        this.productoSelecionado = producto;
        this.descripcionSeleccionado = descripcion;
        this.precioSeleccionado = precio;
        this.idSeleccionado = _id;
        this.descuentoSeleccionado = descuento; 
        this.cantidadSeleccionado = cantidad;        
        this.categoSeleccionado = categoria;


    },
    async actualizarProducto(){
            // Construir el objeto con los datos actualizados del producto
            this.isLoading = true; 
        try {
        // Hacer la solicitud para actualizar el producto
        console.log("categoria:",this.categoSeleccionado, ", cantidad:",this.cantidadSeleccionado, ", imagen:",this.image, ", descuento:",this.descuentoSeleccionado, ", sku:",this.skuSeleccionado, ", producto:",this.productoSelecionado, ", descripcion:",this.descripcionSeleccionado, ", precio:",this.precioSeleccionado, "id:",this.idSeleccionado);
        const response = await AuthService.actualizarProducto(this.categoSeleccionado, this.cantidadSeleccionado, this.image,this.descuentoSeleccionado, this.skuSeleccionado, this.productoSelecionado, this.descripcionSeleccionado, this.precioSeleccionado, this.idSeleccionado);

        // Manejar la respuesta del servidor
        console.log('Producto actualizado:', response.message);
        if (response.message === 'Producto actualizado exitosamente') {
            console.log("Se guardo con exito");
        //    this.userData = await AuthService.getProductos(this.userEmail);
            console.log(this.userData)
            window.location.reload();
        } 
        // Aquí puedes realizar otras acciones después de actualizar el producto, si es necesario
    } catch (error) {
        console.error('Error al actualizar el producto:', error);
        // Aquí puedes manejar el error, mostrar un mensaje al usuario, etc.
    } finally{
        this.isLoading = false; 
    }
    },
    eliminaProducto(sku, _id) {
        this.skuSeleccionado = sku;
        this.idSeleccionado = _id;
        console.log('SKU seleccionado:', this.skuSeleccionado);
        console.log('ID seleccionado:', this.idSeleccionado);
        // console.log(this.idSeleccionado);
        },
    async eliminar() {
        //this.isLoading = true; 
        try {
            console.log('Borrando...');
            const response = await AuthService.elimina(this.idSeleccionado);
            console.log(response);
            if (response.status === 200) {
             console.log("Se elimino con exito");
             window.location.reload();
            } 
            //console.log(response); 
        } catch (error) {
            console.log(error)
        } finally {
          this.isLoading = false; 
      }
    }
    },
  components: {
    LoaderComponent
  }
}
</script>
<style scoped>
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn {
    letter-spacing: -.02em;
}

.btn-block {
    display: block;
    width: 100%;
}
.btn-primary {
    color: #fff;
    background-color: #3755be;
    border-color: #3755be;
}
 
.btn-primary:hover {
    color: #fff;
    background-color: #2e48a0;
    border-color: #2c4396;
}
</style>