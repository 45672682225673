<template>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Configurar PayPal</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <label for="MetodoPagoPaypal" class="form-label"><b>PayPal Email</b></label>
              <div class="input-group has-validation">
                <input type="text" class="form-control" id="MetodoPagoPaypal" v-model="paypalUser" required>
              </div>
              <br>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn-block btn btn-success" @click="actualizarPagoPayPal">
              {{ isLoading ? '' : 'Guardar' }}
              <LoaderComponent v-if="isLoading"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import LoaderComponent from '@/components/LoaderComponent.vue';
  import AuthService from '@/services/AuthService.js';
  
  export default {
    components: {
      LoaderComponent
    },
    data() {
      return {
        isLoading: false,  
        paypalUser: '',
        checked: true
      };
    },
    props: {
      nombreTienda: {
        type: String,
        required: true
      },
      idTienda: {
        type: [String, Number],
        required: true
      },
      userTienda: {
        type: String,
        required: true
      }
    },
    watch: {
      idTienda: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.verificarPayPalEmail();
          }
        }
      },
      paypalUser(newValue) {
        this.$emit('update:paypalUser', newValue);
      }
    },
    methods: {
      async actualizarPagoPayPal() {
        this.isLoading = true;
        this.checked= true
        try {
          // Lógica para actualizar el pago con PayPal
          const response = await AuthService.metodoDePago(this.nombreTienda, this.idTienda, this.userTienda, this.paypalUser, 'PayPal', this.checked);
          console.log("PayPal:",response);
          // Aquí puedes añadir la lógica real para el pago con PayPal
          alert('Pago actualizado exitosamente');
        } catch (error) {
          console.error('Error al actualizar el pago:', error);
          alert('Hubo un error al actualizar el pago');
        } finally {
          this.isLoading = false;
        }
      },
      async verificarPayPalEmail() {
        try {
          console.log("idTienda:", this.idTienda);
          const idTiendaStr = String(this.idTienda);
          const response = await AuthService.obtenerPayPalEmail(idTiendaStr, 'PayPal');
          if (response) {
            console.log("PayPal:",response)
            this.paypalUser = response.metodos.usuario;
          }
        } catch (error) {
          console.error('Error al verificar el email de PayPal:', error);
        }
      }
    }
  };
  </script>
  