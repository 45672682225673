<template>
<div class="mensaje">
    <MensajeComponent />
</div>
<section class="row no-gutters min-vh-100 p-0 forma">
      <div class="col-lg-4 bg-primary-3 d-flex justify-content-end">
        <img src="../assets/img/article-9.jpg" alt="Image" class="bg-image">
        <div class="divider divider-vertical d-none d-lg-block">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none" width="100%" height="100%" version="1.1" class="injected-svg" data-src="assets/img/dividers/divider-vertical-1.svg">
     <path d="M0,0 L100,0 L100,100 L0,100 C66.6666667,83.3333333 100,66.6666667 100,50 C100,33.3333333 66.6666667,16.6666667 0,0 Z" fill="#ffffff"></path>
</svg>
        </div>
      </div>
      <div class="col px-5 position-relative d-flex align-items-center">
        <div class="row justify-content-center w-100">
          <div class="col-md-8 col-lg-7 col-xl-6">
            <div class="text-center"> <img src="../assets/img/logo-2.jpg" alt="Image" class="text-center"></div>
            <div class="text-center mb-4">
              <h1 class="mb-1">Crear Cuenta</h1>
              <span>No se requiere tarjeta de crédito</span>
            </div>
            <form @submit.prevent="register" >
                <div class="form-group">
                <input type="text" placeholder="Nombre" v-model="name" name="name" class="form-control">
              </div>
              <div class="form-group">
                <input type="text" placeholder="Apellido" v-model="lname" name="lname" class="form-control">
              </div>
              <div class="form-group">
                <input type="email" placeholder="Correo Electronico" v-model="email" name="email" class="form-control">
                <span v-if="emailError" class="error">{{ emailError }}</span>
              </div>
              <div class="form-group">
                <input type="password" placeholder="Contraseña" v-model="psw" name="psw" class="form-control">
                <small class="text-muted">Debe tener al menos 8 caracteres.</small>
                <br>
                <span v-if="passwordError1" class="error">{{ passwordError1 }}</span>
              </div>
              <div class="form-group">
                <input type="password" placeholder="Confirmar constraseña" v-model="pswRepeat" name="pswRepeat" class="form-control">
                <span v-if="passwordError2" class="error">{{ passwordError2 }}</span>
              </div>
              <div class="form-group">
                <button class="btn-block btn btn-primary" type="submit">
                    {{ isLoading ? '' : 'Crear Cuenta' }}
                    <LoaderComponent v-if="isLoading"/>
                </button>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="signup-agree" required>
                <label class="custom-control-label text-small text-muted" for="signup-agree"> 
                  &nbsp; Estoy de acuerdo con los <a href="https://saldi-web.onrender.com/terms" target="_blank">Terminos y Condiciones</a>
                </label>
              </div>
              <hr>
              <div class="text-center text-small text-muted">
                <span>Ya tienes una cuenta? <a href="/">Inicia sesión aqui</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import AuthService from '@/services/AuthService.js';
import LoaderComponent from '@/components/LoaderComponent.vue';
import MensajeComponent from '@/components/MensajeComponent.vue';
export default {
    data() {
    return {
        name: '',
      lname:'',
      email: '',
      emailError:'',
      psw: '',
      pswRepeat: '',
      passwordError1: '',
      passwordError2: '',
    //  registrarMsg: 'Registrar',
      isLoading: false
    };
  },
  methods: {
    async register() {
      // Reset errors
      this.emailError = '';
      this.passwordError1 = '';
      this.passwordError2 = '';
    //  this.registrarMsg= '';
      this.isLoading = true; 

 
      if (!this.email) {
        this.emailError = 'Introduce tu correo electronico.';
      }
      if (!this.psw) {
        this.passwordError1 = 'Introduce tu contraseña.';
      }
      if (!this.pswRepeat) {
        this.passwordError2 = 'Confirma tu contraseña.';
      }

      if (this.psw !== this.pswRepeat) {
        this.passwordError2 = 'Las contraseñas no coinciden.';
      }

      if (!this.emailError && !this.passwordError1 && !this.passwordError2) {
        try {
            console.log('Registrando...');
            const response = await AuthService.register(this.name, this.lname, this.email, this.psw);
            if (response.status === 200) {
              // Muestra el mensaje de registro exitoso
              const mensajeDiv = document.querySelector('.mensaje');
              const formaForm = document.querySelector('.forma');
              if (mensajeDiv) {
                mensajeDiv.style.display = 'block';
              }
              if (formaForm) {
                formaForm.style.display = 'none';
              }
            } 
            console.log(response); 
        } catch (error) {
            console.log(error)
        } finally {
          this.isLoading = false; 
      }
        
      }
    }
  },
  components: {
    LoaderComponent,
    MensajeComponent,
  }
};
</script>
<style scoped>
.body {
    height: 100%;
}
.error {
  color: #ed4c78;
}
.mensaje{
  display: none;
}

/**/
.min-vh-100 {
    min-height: 100vh !important;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.bg-image:not([class*=absolute]) {
    position: absolute;
}
img.bg-image {
    object-fit: cover;
}
.bg-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
.no-gutters>.col, .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.d-flex {
    display: flex !important;
}
.bg-primary-3 {
    background-color: #1b1f3b !important;
}
@media (min-width: 992px) {
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }


}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
}
.position-relative {
    z-index: 1;
}

.pl-5, .px-5 {
    padding-left: 3rem !important;
}
.pr-5, .px-5 {
    padding-right: 3rem !important;
}
.position-relative {
    position: relative !important;
}
.align-items-center {
    align-items: center !important;
}
.d-flex {
    display: flex !important;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
.w-100 {
    width: 100% !important;
}
.justify-content-center {
    justify-content: center !important;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
}
.form-group {
    position: relative;
}
.form-group {
    margin-bottom: 1rem;
}
@media (min-width: 992px) {
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn {
    letter-spacing: -.02em;
}

.btn-block {
    display: block;
    width: 100%;
}
.btn-primary {
    color: #fff;
    background-color: #3755be;
    border-color: #3755be;
}
 
.btn-primary:hover {
    color: #fff;
    background-color: #2e48a0;
    border-color: #2c4396;
}
a {
    font-weight: 600;
    color: #3755be;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #263a83;
    text-decoration: none;
}
.text-small {
    font-size: .875rem;
    line-height: 1.25rem;
}
.text-small, small {
    font-weight: 600;
}
.text-muted {
    color: #adb5bd !important;
}
.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .875rem + 2px);
    padding: .4375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: .3125rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}
</style>