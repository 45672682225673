import axios from 'axios';
import CryptoJS from 'crypto-js';

const baseURL = 'https://mywedding-backend.onrender.com'; 
const serverimagenes = 'https://img-server-zxm5.onrender.com';

//const serverimagenes = 'http://localhost:3000';

export default {
    async register(name, lname, email, password) {
      console.log(name, lname, email, password);
        try{
            const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
            const hPassword = CryptoJS.SHA256(password).toString();
            const headers = {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + 'accessToken',
                'Accept': 'application/json'
            };
    
            return axios.post(`${baseURL}/registro/`, { name, lname, email, hPassword }, { headers });
        }catch(e){
          console.log('Error:', e);
          throw e; 
        }
      },
  async login(email, password) {
    try{
        const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
        const hPassword = CryptoJS.SHA256(password).toString();
        const headers = {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + 'accessToken',
            'Accept': 'application/json'
        };

        console.log(headers);
        console.log('Email: ' + email);
        return axios.post(`${baseURL}/login/`, { email, hPassword }, { headers });
    }catch(e){
      console.log('Error:', e);
      throw e; 
    }
  },
  async getUserInfo(email) {
    try {
        const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
        const token = sessionStorage.getItem('token');
        if (!token) {
            throw new Error('No hay token de sesión.');
        }
        
        const response = await axios.get(`${baseURL}/getInfo/${email}`, {
            headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
            }
        });  
      return response.data;
    } catch (error) {
      throw new Error('Error al obtener la información del usuario:', error);
    }
  },
  async registraProducto(userEmail, producto, categoria, precio, descuento, descripcion, cantidad, sku, image, idTienda) {
    
    let headers;

    try{
      const formData = new FormData();
      formData.append('image', image);

        const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';

        headers = {
          'X-CSRFToken': csrfToken,
          'Authorization': 'Bearer ' + 'accessToken',
          'Accept': 'application/json'
        };

        const response = await axios.post(`${serverimagenes}/upload`, formData, {headers});
        const img  = response.data.filename;
        console.log('Nombre del archivo:', img);
        headers = {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + 'accessToken',
          'Accept': 'application/json'
        };
        return axios.post(`${baseURL}/producto/`, { userEmail, producto, categoria, precio, descuento, descripcion, cantidad, sku, img, idTienda }, { headers });
    }catch(e){
      console.log('Error:', e);
      throw e; 
    }
  },
  async getProductos(email) {
    try {
        const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
          
        const response = await axios.get(`${baseURL}/producto/${email}`, {
            headers: {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
                'Authorization': `Bearer X`,
                'Accept': 'application/json'
            }
        });  
        console.log(response);
        // Verificar si la respuesta tiene datos
        if (response && response.data) {
            console.log(response.data);
            return response.data;
        }
        if (response.status === 404){
            // El recurso no fue encontrado, puedes manejar este caso de acuerdo a tus necesidades
            console.log('El producto no fue encontrado para el usuario:', email);
            return null; // O puedes devolver un objeto vacío, dependiendo de tu lógica
        }
    } catch (error) {
        // Manejar el error adecuadamente
        console.error('Error al obtener la información del usuario:', error);
        throw new Error('Error al obtener la información del usuario:', error);
    }
},
  async elimina(id) {
    console.log(id);
    const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
    try {
        const response = await axios.delete(`${baseURL}/producto/${id}`, {
            headers: {
                'X-CSRFToken': csrfToken,
                'Authorization': 'Bearer ' + 'tuToken'
            }
        });
        console.log('Respuesta del servidor:', response);
        return response; // Devuelve los datos de la respuesta
    } catch (error) {
        console.error('Error al eliminar el recurso:', error);
        throw error; // Lanza el error para manejarlo en la llamada a la función
    }
  },
  async registraTienda(email, tienda) {
    console.log(email);
    console.log(tienda);
        try{
            const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
            const headers = {
                'X-CSRFToken': csrfToken,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + 'accessToken',
                'Accept': 'application/json'
            };
    
            return axios.post(`${baseURL}/tienda/`, { email, tienda }, { headers });
        }catch(e){
          console.log('Error:', e);
          throw e; 
        }
      },
      async actualizarProducto(categoSeleccionado, cantidadSeleccionado, imgSeleccionado, descuentoSeleccionado, skuSeleccionado, productoSelecionado, descripcionSeleccionado, precioSeleccionado, idSeleccionado) {
        try {
          console.log('Actualiza producto....')
          console.log(categoSeleccionado, cantidadSeleccionado, imgSeleccionado, descuentoSeleccionado, skuSeleccionado, productoSelecionado, descripcionSeleccionado, precioSeleccionado, idSeleccionado);
          const formData = new FormData();
          formData.append('image', imgSeleccionado);
          const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';

          const headers = {
            'X-CSRFToken': csrfToken,
            'Authorization': 'Bearer ' + 'accessToken',
            'Accept': 'application/json'
          };
          let img = '';
          if (imgSeleccionado) {
            const response = await axios.post(`${serverimagenes}/upload`, formData, {headers});
            img  = response.data.filename;
          }
          console.log('Nombre de la imagen from serverimagenes:', img);

            console.log(idSeleccionado)
            const res = await axios.put(`${baseURL}/producto/`, {
              idSeleccionado,
              skuSeleccionado,
              categoSeleccionado, 
              cantidadSeleccionado, 
              img, 
              descuentoSeleccionado, 
              productoSelecionado, 
              descripcionSeleccionado, 
              precioSeleccionado, 
            }, { headers });
            return res.data; // Devolver los datos de la respuesta si es necesario
        } catch (error) {
            console.log('Error:', error);
            throw error; // Lanzar el error para que pueda ser manejado en la llamada al método
        }
    },
      async actualizarTema(userEmail, idTienda, tema) {
        try {
          console.log('Actualiza producto....',idTienda, userEmail, tema)
          
          const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';

          const headers = {
            'X-CSRFToken': csrfToken,
            'Authorization': 'Bearer ' + 'accessToken',
            'Accept': 'application/json'
          };
          
            const res = await axios.put(`${baseURL}/theme/`, {
              userEmail,
              idTienda,
              tema
            }, { headers });
            return res.data; // Devolver los datos de la respuesta si es necesario
        } catch (error) {
            console.log('Error:', error);
            throw error; // Lanzar el error para que pueda ser manejado en la llamada al método
        }
    },
    async getOrdenes(email) {
      try {
          const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
            
          const response = await axios.get(`${baseURL}/ordenes/${email}`, {
              headers: {
                  'X-CSRFToken': csrfToken,
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer X`,
                  'Accept': 'application/json'
              }
          });  
          console.log(response);
          // Verificar si la respuesta tiene datos
          if (response && response.data) {
              console.log(response.data);
              return response.data;
          }
          if (response.status === 404){
              // El recurso no fue encontrado, puedes manejar este caso de acuerdo a tus necesidades
              console.log('El producto no fue encontrado para el usuario:', email);
              return null; // O puedes devolver un objeto vacío, dependiendo de tu lógica
          }
      } catch (error) {
          // Manejar el error adecuadamente
          console.error('Error al obtener la información del usuario:', error);
          throw new Error('Error al obtener la información del usuario:', error);
      }
  },
  async metodoDePago(nombreTienda, idTienda, userTienda, userPayPal, userMetodo, activo){
    console.log("Actualizando pago con PayPal...");
    console.log('Nombre de la tienda:', nombreTienda);
    console.log('Id de la tienda:', idTienda);
    console.log('user de la tienda:', userTienda);
    console.log('paypalUser:', userPayPal);
    console.log('userMetodo:', userMetodo);
    console.log('userMetodo:', activo);

    try{
      const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
      const headers = {
          'X-CSRFToken': csrfToken,
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + 'accessToken',
          'Accept': 'application/json'
      };

      return axios.post(`${baseURL}/metodoPago/`, { nombreTienda, idTienda, userTienda, userPayPal, userMetodo, activo }, { headers });
    }catch(e){
      console.log('Error:', e);
      throw e; 
    }
  },
  async obtenerPayPalEmail(idTienda, metodo) {
    try {
        const csrfToken = '621d237394bec8f639566cfd63ef70b3bd825d2fcea5da1992aa36a8a3c05e5b';
        const token = sessionStorage.getItem('token');
        if (!token) {
            throw new Error('No hay token de sesión.');
        }
        console.log(idTienda);
    //    console.log(String(idTienda));
        const response = await axios.get(`${baseURL}/metodoPago/${idTienda}/${metodo}`, {
            headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
            }
        });  

      return response.data;
    } catch (error) {
      throw new Error('Error al obtener la información del usuario:', error);
    }
  }
  
};